@keyframes slide-down {
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down-banners {
  0% {
    transform: translateY(-200%);;
    opacity: 0;
  }

  100% {
    transform: translateY(-50%);
    opacity: 1;
  }
}

.slide-down-banner-animation {
  animation: slide-down-banners 500ms;
  animation-fill-mode: forwards;
}

body {
  .main-header {
    .header-slider-title {
      font-size: 2.5rem;
      font-weight: 400;
      margin-bottom: 0;

      strong {
        font-size: 4.5rem;
      }
    }

    .header-slider-title:nth-of-type(1) {
      animation: slide-down 500ms;
    }

    .header-slider-title:nth-of-type(2) {
      opacity: 0;
      animation: slide-down 500ms;
      animation-delay: 100ms;
      animation-fill-mode: forwards;
    }
  }

  .homepage-search {
    animation: slide-down 500ms;
  }

  .header-container {
    background: #fcfcfc;

    .btn-menu {
      .menu-icon>span {
        background: color('primary');
      }

      .menu-text {
        color: color('primary');
      }
    }

    .header-nav-main {
      background: #fcfcfc !important;

      .company-brand img {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .nav-list {
        li {
          font-weight: 400;
        }

        li.active {
          border-bottom: none !important;
          color: color('primary');
        }
      }

      .btn-menu {
        padding-top: 0;
        padding-bottom: 0;
      }

      .lbl_locations {
        li a {
          font-weight: 400 !important;
          text-transform: none;
        }
      }

      .navigation-wrapper {
        padding: 0;

        ul.nav-list {
          display: flex !important;
        }
      }
    }

    &.scrolled {
      .header-nav-main {
        .company-brand img {
          height: 49px !important;
          margin-top: 11px;
          margin-bottom: 11px;
        }
      }
    }
  }

  .header-nav-top {
    height: 50px;
    display: flex;
    align-items: center;

    .dropdown-menu {
      .dropdown-item {
        color: white;
      }
    }
  }

  @media (min-width: 992px) {
    .main-navigation {
      .nav-list {
        .nav-item a:hover {
          color: white;
        }
      }
    }
  }

  .banners-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;

    .banner-wrapper {
      .banner-content-wrapper {
        opacity: 0;
        bottom: unset;
        width: 100%;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;

        h2 {
          text-transform: none;
          font-size: 2.5rem;
          font-weight: 700;
        }

        p {
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 1;
        }

        .separator {
          display: none;
        }
      }

      a.more {
        position: absolute;
        width: auto;
        height: auto;
        bottom: 0;
        background: white;
        color: grey;
        padding: 6px 16px;
      }
    }
  }

  .properties-container, .intro-section, .group-property-section {
    background: #f2f2f2;
  }

  .properties-container, .group-property-section {

    .property-card {
      border: none;
      background: white;
      transition: background 300ms ease-in;

      .card-text, li {
        transition: color 300ms ease-in;
      }

      .property-id-icon:before,
      .property-bed-icon:before,
      .property-bathroom-icon:before,
      .property-area-icon:before,
      .property-infield-area-icon:before,
      .property-floor-icon:before,
      .property-rooms-icon:before,
      .property-parking-icon:before,
      .property-distance-sea-icon:before,
      .property-lux-patch:before {
        transition: background 300ms ease-in;
      }

      .card-heading:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #0080bc;
        opacity: 0;
        transition: opacity 300ms ease-in;
        pointer-events: none;
      }

      &:hover {
        background: #0080bc;

        .card-title a, .card-text, .property-details li, .price a {
          color: white !important;
        }

        .property-id-icon:before,
        .property-bed-icon:before,
        .property-bathroom-icon:before,
        .property-area-icon:before,
        .property-infield-area-icon:before,
        .property-floor-icon:before,
        .property-rooms-icon:before,
        .property-parking-icon:before,
        .property-distance-sea-icon:before,
        .property-lux-patch:before {
          background-color: white;
        }

        .card-heading::after {
          opacity: 0.3;
        }
      }
    }
  }

  .main-footer .footer-section {
    h4.block-title::after {
      height: 1px;
    }

    .footer-share {
      .a2a_kit > a:hover {
        svg path {
          fill: white !important;
        }
      }
    }

    .footer-nav {
      ul li a {
        width: 100% !important;
      }
    }

    @media (max-width: 991px) {
      .social-media {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media (max-width: 1023px) {
  body {
    .header-slider-item {
      display: none !important;
    }

    #content {
      padding-top: 110px;
    }
  }
}


body:not(.homepage) {
 .group-property-section {
   margin-top: 20px;
 }
}